<template>
    <v-container class="panel-wrapper pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="Invoices">
                    <v-tabs class="panel-tabs"
                            v-model="active"
                            @change="changeHandler"
                            :slider-color="this.$vuetify.theme.primary">
                        <!--<v-tab active-class="tab-active"-->
                        <!--ripple>-->
                        <!--ALL-->
                        <!--</v-tab>-->
                        <v-tab active-class="tab-active"
                               class="text-none"
                               v-for="(item,index) in invoicesTabs"
                               :key="index"
                               ripple>
                            {{item.name}}
                        </v-tab>
                    </v-tabs>
                    <v-divider></v-divider>
                    <v-container class="px-0"  fluid>
                        <v-data-table class="elevation-1" :headers="headers" :items="invoicesList" :loading="loading"
                                      headers-length="50px" hide-actions>
                            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                            <template v-slot:items="{item}">
                                <tr style="cursor: pointer" @click="$router.push(`/invoice-detail/${item.uuid}`)">
                                    <td>{{ item.id }}</td>
                                    <td class="text-xs-left" style="white-space: nowrap;">{{ (item.packages && item.packages.length) ? item.packages[0].name : '--'}}</td>
                                    <td class="text-xs-left">{{ (item.packages && item.packages.length) ? item.packages[0].product_name : '--'}}</td>
                                    <td class="text-xs-left">{{formatDate( item.created )}}</td>
                                    <td class="text-xs-left">{{formatDate( item.due_date )}}</td>
                                    <td class="text-xs-left">{{ item.paid_date?formatDate( item.paid_date ): '--'}}</td>
                                    <td class="text-xs-left">{{ item.payment_method }}</td>
                                    <td class="text-xs-left">${{ item.total }}</td>
                                    <td class="text-xs-left" :style="{color: statusColors(item.status)}">{{ item.status }}</td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <div style="min-height: 192px;position: relative;">
                                    <Spin v-show="loading"></Spin>
                                    <Empty v-show="!loading ">
                                        <p v-if="active === 0" style="text-align: left">
                                            It seems that no invoices exist right now. <br>
                                            To get an invoice, you will need to: <br>
                                            1.Complete your billing information. <br>
                                            2.Generate an invoice by clicking "Renew" on the "My Applications" page.
                                        </p>
                                    </Empty>
                                </div>
                            </template>
                        </v-data-table>
						<v-layout class="mt-3" v-if="total>0">
							<v-spacer></v-spacer>
							<v-flex v-if="total>1">
								<v-pagination @input="getInvoices" :disabled="loading" v-model="page" :length="total" ></v-pagination>
							</v-flex>
						</v-layout>
                    </v-container>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {formatDate} from '@/module/utils/date'
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import TabCard from '@/components/TabCard.vue'
    const colorMap = {
        'Unpaid': '#FF5252',
        'Paid': '#10C610',
        'Cancelled': '#607D8B',
        'Fully Refunded': '#673ab7',
        'Partially Refunded': '#9c27b0'
    }
    export default {
        name: "Invoices",
        components: {
            Empty,
            TabCard,
            Spin
        },
        data() {
            return {
                page: 0,
                total: 0,
                loading: false,
                active: 0,
                invoicesTabs: [
                    {name: 'All', status: '',},
                    {name: 'Unpaid', status: 'Unpaid'},
                    {name: 'Paid', status: 'Paid'},
                    {name: 'Cancelled', status: 'Cancelled'},
                    {name: 'Refunded', status: 'Fully Refunded,Partially Refunded'}
                ],
                headers: [
                    {text: 'ID', sortable: false, value: 'id'},
                    {text: 'Application Name', sortable: false, value: 'id'},
                    {text: 'Product', sortable: false, value: 'id'},
                    {text: 'Invoice Date', sortable: false, value: 'invoicesDate'},
                    {text: 'Due Date', sortable: false, value: 'dueDate'},
                    {text: 'Date Paid', sortable: false, value: 'paidDate'},
                    {text: 'Payment Method', sortable: false, value: 'method'},
                    {text: 'Total', sortable: false, value: 'total'},
                    {text: 'Status', sortable: false, value: 'status'},
                ],
                invoicesList: []
            }
        },
        computed: {
            statusColors() {
                return (st) => {
                    return colorMap[st]
                }
            },
            currentStatus() {
                return this.invoicesTabs[this.active].status
            }
        },
        methods: {
            formatDate,
            changeHandler() {
                this.invoicesList = []
                this.loading = false
                this.getInvoices()
            },
            getInvoices(page = 1) {
                if (this.loading) {
                    return
                }
                this.loading = true
                let sendStatus = this.invoicesTabs[this.active].status
                this.$http.get('/invoices/', {
                    params: {
                        client_id: this.$store.state.client.clientInfo.id,
                        page_size: 12,
                        status: sendStatus,
                        page: page
                    }
                }).then(res => {
                    if (sendStatus !== this.currentStatus) {
                        return
                    }
                    this.invoicesList = res.results
                    this.total = res.page_total
                    this.page = res.page
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                    this.$message.error(err.detail)
                })
            }
        },
        created() {
            let active = this.$route.query.active
            if(active || active === 0 ){
                this.active= parseInt(active)
            }
            this.getInvoices()

            this.$SDK.track({
                pageName : 'Billing_Invoices'
            })
        }
    }
</script>
