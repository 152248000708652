var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Invoices" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "panel-tabs",
                      attrs: { "slider-color": this.$vuetify.theme.primary },
                      on: { change: _vm.changeHandler },
                      model: {
                        value: _vm.active,
                        callback: function($$v) {
                          _vm.active = $$v
                        },
                        expression: "active"
                      }
                    },
                    _vm._l(_vm.invoicesTabs, function(item, index) {
                      return _c(
                        "v-tab",
                        {
                          key: index,
                          staticClass: "text-none",
                          attrs: { "active-class": "tab-active", ripple: "" }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.name) +
                              "\n                        "
                          )
                        ]
                      )
                    }),
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-container",
                    { staticClass: "px-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-data-table",
                        {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.invoicesList,
                            loading: _vm.loading,
                            "headers-length": "50px",
                            "hide-actions": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "items",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "tr",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.push(
                                            "/invoice-detail/" + item.uuid
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("td", [_vm._v(_vm._s(item.id))]),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-xs-left",
                                          staticStyle: {
                                            "white-space": "nowrap"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.packages &&
                                                item.packages.length
                                                ? item.packages[0].name
                                                : "--"
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.packages &&
                                                item.packages.length
                                                ? item.packages[0].product_name
                                                : "--"
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.formatDate(item.created))
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(item.due_date)
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.paid_date
                                                ? _vm.formatDate(item.paid_date)
                                                : "--"
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v(_vm._s(item.payment_method))]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [_vm._v("$" + _vm._s(item.total))]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-xs-left",
                                          style: {
                                            color: _vm.statusColors(item.status)
                                          }
                                        },
                                        [_vm._v(_vm._s(item.status))]
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "no-data",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "min-height": "192px",
                                        position: "relative"
                                      }
                                    },
                                    [
                                      _c("Spin", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.loading,
                                            expression: "loading"
                                          }
                                        ]
                                      }),
                                      _c(
                                        "Empty",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.loading,
                                              expression: "!loading "
                                            }
                                          ]
                                        },
                                        [
                                          _vm.active === 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "text-align": "left"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            It seems that no invoices exist right now. "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                            To get an invoice, you will need to: "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                                            1.Complete your billing information. "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    '\n                                            2.Generate an invoice by clicking "Renew" on the "My Applications" page.\n                                        '
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { color: "blue", indeterminate: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "progress",
                                fn: function() {
                                  return undefined
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm.total > 0
                        ? _c(
                            "v-layout",
                            { staticClass: "mt-3" },
                            [
                              _c("v-spacer"),
                              _vm.total > 1
                                ? _c(
                                    "v-flex",
                                    [
                                      _c("v-pagination", {
                                        attrs: {
                                          disabled: _vm.loading,
                                          length: _vm.total
                                        },
                                        on: { input: _vm.getInvoices },
                                        model: {
                                          value: _vm.page,
                                          callback: function($$v) {
                                            _vm.page = $$v
                                          },
                                          expression: "page"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }